import BaseModel from './BaseModel';
import User from './User';
import Project from './Project';
import Change from './Change';
import moment from 'moment';
import type { Moment } from 'moment';

/** Class representing a routinea action. */
export class RoutineAction {
    /**
     * Actions types constants
     */
    static ACTION_CREATE_CHANGE    = 'create_change';
    static ACTION_DUPLICATE_CHANGE = 'duplicate_change';
    static ACTION_NOTIFY           = 'notify';

    type: string;
    options: AnyObj;

    constructor(properties: AnyObj) {
        this.type    = properties.type;
        this.options = properties.options;
    }

    static actionTypes() : string[] {
        return [
            RoutineAction.ACTION_CREATE_CHANGE,
            RoutineAction.ACTION_DUPLICATE_CHANGE,
            RoutineAction.ACTION_NOTIFY
        ];
    }
}

/** Class representing a routine. */
class Routine extends BaseModel {
    /**
     * Events types constants
     */
    static EVENT_RELEASE_CREATED = 'release.created';
    static EVENT_MR_OPEN         = 'mr.open';
    static EVENT_MR_APPROVED     = 'mr.approved';
    static EVENT_CHANGE_CREATED  = 'change.created';

    _id: string;
    createdAt?: Moment;
    updatedAt?: Moment;
    name: string;
    trigger: {
        frequency: string;
        events: string[];
    };
    actions: RoutineAction[];
    author?: User;
    project?: Project;
    change?: Change;

    constructor(properties: AnyObj) {
        super({});

        this._id       = properties._id;
        this.createdAt = properties.createdAt ? moment(properties.createdAt) : undefined;
        this.updatedAt = properties.createdAt ? moment(properties.createdAt) : undefined;
        this.name      = properties.name;
        this.trigger   = properties.trigger || {
            frequency: '0 10 * * 1',
            events: []
        };
        this.actions   = (properties.actions || []).map((action: AnyObj) => new RoutineAction(action));
        this.author    = properties.author?._id ? new User(properties.author) : properties.author;
        this.project   = properties.project?._id ? new Project(properties.project) : properties.project;
        this.change  = properties.change?._id ? new Change(properties.change) : properties.change;
    }

    static eventTypes() : string[] {
        return [
            Routine.EVENT_CHANGE_CREATED,
            Routine.EVENT_RELEASE_CREATED,
            Routine.EVENT_MR_OPEN,
            Routine.EVENT_MR_APPROVED,
        ];
    }
}

export default Routine;
