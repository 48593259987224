import React, { useState, useEffect } from 'react';
import { useTranslation }  from 'react-i18next';
import { RootState, useAppSelector, useAppDispatch } from '../../redux';
import { resetRoutine, loadRoutines, createRoutine, deleteRoutine } from '../../redux';
import { Icon } from '../Icon';
import { Confirm } from '../Confirm';
import { Routine } from '../../models';
import { Button } from '../../components';
import { RoutineForm } from './RoutineForm';

interface RoutinesManagerProps {
    authorId?: string;
    projectId?: string;
    changeId?: string;
}

function RoutinesManager(props: RoutinesManagerProps) {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();

    const [active, setActive]                 = useState<string|null>(null);
    const [toDelete, setToDelete]             = useState<string|null>(null);
    const [newRoutineName, setNewRoutineName] = useState();

    const { authorId, projectId, changeId } = props;

    const { routines } = useAppSelector((state: RootState) => state.routines);

    useEffect(() => {
        dispatch(loadRoutines({ author: authorId, project: projectId, change: changeId }));
    }, [dispatch]);

    const addRoutine = () => {
        const callback = (err: Error | null, newRoutine?: AnyObj | AnyObj[] | null) => {
            setNewRoutineName(undefined);
            setActive((newRoutine as Routine)?._id);
        };

        dispatch(createRoutine(new Routine({
            name: newRoutineName,
            author: authorId,
            project: projectId,
            change: changeId
        }), /*params*/{}, callback));
    };

    const removeRoutine = () => {
        const callback = (err: Error | null, newRoutine?: AnyObj | AnyObj[] | null) => {
            setToDelete(null);
        };

        if (toDelete)
            dispatch(deleteRoutine(toDelete, callback));
    };

    const closeRoutine = () => {
        setActive(null);
        dispatch(resetRoutine(/*onlyItem*/true));
    };

    const handleKeyPress = (e: AnyObj, enterCallback: (shift: boolean) => void, escapeCallback?: () => void) => {
        if (e.key === 'Enter' || e.code === 'Enter')
            enterCallback(!e.shiftKey);
        if (escapeCallback && (e.key === 'Escape' || e.code === 'Escape'))
            escapeCallback();
    }

    const routineForm = React.useMemo(() => (routine: Routine) => {
        return (
            <div key={routine._id} className="flex flex-col">
                <div onClick={() => setActive(active === routine._id ? null : routine._id)} className={`cursor-pointer flex justify-between items-center routine border rounded ${active === routine._id ? 'rounded-b-none bg-gray-50' : 'bg-gray-50'}`}>
                    <div className="text-gray-900 p-2 flex flex-row items-center space-x-1">
                        <Icon type="routine" />
                        <h3>{routine.name}</h3>
                    </div>
                    <Icon type={active === routine._id ? 'up' : 'down'} className="mr-2" />
                </div>
                {active === routine._id && (
                    <div>
                        <RoutineForm
                            _id={routine._id}
                            authorId={authorId}
                            projectId={projectId}
                            changeId={changeId}
                            onCancel={ closeRoutine }
                            onRemove={() => setToDelete(routine._id)}
                        />
                    </div>
                )}
            </div>
        );
    }, [active]);

    return (
        <div className="routines flex flex-row">
            <div className="flex-1 flex flex-col space-y-5">
                {routines?.map((routine: Routine) => routineForm(routine)) }
                <div className="flex flex-row items-center space-x-0 border bg-blue-100 rounded mx-2">
                    <label className="max-w-[200px] flex-1 flex items-center text-sm px-2">
                        {t('projects.add_routine')}
                    </label>
                    <div className="flex-1">
                        <input
                            type="text"
                            value={newRoutineName}
                            onChange={(e: any) => { setNewRoutineName(e.target.value); }}
                            onKeyUp={e => handleKeyPress(e, addRoutine)}
                            placeholder={t('projects.add_routine_placeholder') || ''}
                            className="border-0 rounded rounded-l-none text-sm focus:ring-0 w-full"
                        />
                    </div>
                    {newRoutineName && (
                        <Button
                            className="h-full rounded-l-none"
                            small
                            color="primary"
                            icon="plus"
                            iconSize={4}
                            title={t('common.add')}
                            onClick={ addRoutine }
                        />
                    )}
                </div>
            </div>
            <Confirm
                visible={!!toDelete}
                title={ t('projects.are_you_sure_to_delete_this_routine') || '' }
                onConfirm={() => { removeRoutine() }}
                onCancel={() => setToDelete(null)}
            />
        </div>
    );
}

export default RoutinesManager;
