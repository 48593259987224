import React, { useState, useEffect } from 'react';
import { useTranslation }  from 'react-i18next';
import { RootState, useAppSelector, useAppDispatch } from '../../../redux';
import { loadRoutine, updateRoutine, changeRoutine } from '../../../redux';
import { Routine, RoutineAction } from '../../../models';
import { Confirm } from '../../Confirm';
import { Loader } from '../../Loader';
import { Icon } from '../../Icon';
import { Button } from '../../Button';
import { MultiSelect } from '../../MultiSelect';
import { ActionForm } from '../ActionForm';
import { Select } from '../../Select';
import type { Value } from '../../Select';
import { Cron } from 'react-js-cron';
import type { Locale } from 'react-js-cron';
import 'react-js-cron/dist/styles.css';
import { fr } from './locales';

interface RoutineFormProps {
    _id: string;
    onCancel: () => void;
    onRemove: () => void;
    authorId?: string;
    projectId?: string;
    changeId?: string;
}

function RoutineForm(props: RoutineFormProps) {
    const { _id, authorId, projectId, changeId, onCancel, onRemove } = props;

    const { i18n, t } = useTranslation();
    const dispatch    = useAppDispatch();

    const [routine, setRoutine]           = useState<Routine>();
    const [activeAction, setActiveAction] = useState(-1);
    const [toDelete, setToDelete]         = useState<number>(-1);
    const [typeToAdd, setTypeToAdd]       = useState();

    const storeRoutine = useAppSelector((state: RootState) => state.routines.routine);
    const { loadingOne, updating, deleting } = useAppSelector((state: RootState) => state.routines);

    const isLoading = [loadingOne, updating, deleting].includes('pending');

    useEffect(() => {
        dispatch(loadRoutine(_id));
    }, [dispatch, _id]);

    useEffect(() => {
        setRoutine(storeRoutine);
    }, [storeRoutine]);

    const saveRoutine = (routine: Routine) => {
        dispatch(updateRoutine({ ...routine }));
    };

    const addAction = (type: string) => {
        const changed = new Routine({
            ...routine,
            actions: [
                ...(routine?.actions || []),
                {
                    type,
                    options: {}
                }
            ]
        });
        saveRoutine(changed);
        setTypeToAdd(undefined);
    };

    const removeAction = () => {
        if (toDelete >= 0) {
            const actions = routine?.actions.filter((action: RoutineAction, i: number) => i !== toDelete);
            setRoutine(new Routine({
                ...routine,
                actions
            }));
        }
        setToDelete(-1);
    };

    const updateAction = (i: number, action: RoutineAction) => {
        const actions = [ ...(routine?.actions || []) ];
        actions[i] = action;
        setRoutine(new Routine({
            ...routine,
            actions
        }));
    };

    const updateFrequency = (frequency: string) => {
        setRoutine(new Routine({
            ...routine,
            trigger: {
                ...(routine?.trigger || {}),
                frequency
            }
        }));
    };

    const updateEvents = (events: string[]) => {
        setRoutine(new Routine({
            ...routine,
            trigger: {
                ...(routine?.trigger || {}),
                events
            }
        }));
    };

    const locale = React.useMemo(() => {
        return i18n.language === 'fr' ?  fr as Locale : undefined;
    }, [i18n.language]);

    if (!routine)
        return <Loader />;

    if (routine?._id !== _id)
        return null;

    const options = Routine.eventTypes().map((type: string) => ({
        value: type,
        label: t(`projects.notifications_events_types.${type}`)
    }));

    const handleKeyPress = (e: AnyObj, enterCallback: (shift: boolean) => void, escapeCallback?: () => void) => {
        if (e.key === 'Enter' || e.code === 'Enter')
            enterCallback(!e.shiftKey);
        if (escapeCallback && (e.key === 'Escape' || e.code === 'Escape'))
            escapeCallback();
    }

    return (
        <div className="routine border border-y-0">
            <div className="flex flex-col space-y-4 p-2">
                <div className="grid grid-cols-1 xl:grid-cols-4 gap-2 bg-blue-50 mt-2 p-2 border rounded">
                    <label className="text-sm text-gray-900 col-span-1 flex items-center">
                        {t('projects.routine.name')}
                    </label>
                    <div className="col-span-3">
                        <input
                            type="text"
                            defaultValue={routine?.name || ''}
                            onChange={(e: any) => { routine.name = e.target.value; }}
                            onKeyUp={e => handleKeyPress(e, () => saveRoutine(routine))}
                            placeholder={t('changes.set_deadline_offset') || ''}
                            className="text-sm border rounded border-gray-300 w-full"
                        />
                    </div>
                </div>
                <div className="text-gray-900 text-sm font-bold border-t -mx-2 p-2 pb-0">
                    {t('projects.routine.triggers')}
                </div>
                <div className="flex flex-1 flex-col space-y-2 justify-center items-start p-2 pt-0 border rounded bg-blue-50">
                    <div className="flex flex-row space-x-1 items-center mt-1 text-gray-900 font-medium">
                        <Icon type="timer" />
                        <h3 className="text-sm">{t('projects.routine.with_scheduling')}</h3>
                    </div>
                    <div className="flex flex-row space-x-2 items-center">
                        <div>
                            <input
                                type="text"
                                value={routine?.trigger.frequency}
                                onChange={(e: any) => { updateFrequency(e.target.value); }}
                                className="border-gray-300 rounded text-sm text-center p-1 py-0 h-[32px]"
                                placeholder="* * * * *"
                            />
                        </div>
                        {routine.trigger.frequency && (
                            <>
                                <span className="">
                                    <Icon type="swap" />
                                </span>
                                <div className="text-sm -mb-[10px]">
                                    <Cron
                                        defaultPeriod="month"
                                        value={routine.trigger.frequency}
                                        setValue={(v: any) => { updateFrequency(v); }}
                                        locale={locale}
                                        clearButtonAction="empty"
                                        leadingZero
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="flex flex-1 flex-col space-y-2 justify-center items-start p-2 pt-0 border rounded bg-blue-50">
                    <div className="flex flex-row space-x-1 items-center mt-1 text-gray-900 font-medium">
                        <Icon type="event" />
                        <h3 className="text-sm">{t('projects.routine.on_events')}</h3>
                    </div>
                    <div>
                        <MultiSelect
                            defaultValue={routine.trigger.events as Value[]}
                            options={options}
                            placeholder={t('projects.routine.choose_events') || ''}
                            onChange={(events: any) => { updateEvents(events); }}
                            className="border rounded"
                            optionsWidth="w-[350px]"
                        />
                    </div>
                </div>
            </div>
            <div className="border-t flex flex-col space-y-4 p-2">
                <div className="text-gray-900 text-sm font-bold">
                    {t('projects.routine.actions')}
                </div>
                {routine.actions.map((action: RoutineAction, i: number) => (
                    <div key={i} className="flex flex-col">
                        <div
                            onClick={(e: AnyObj) => setActiveAction(activeAction === i ? -1 : i)}
                            className={`cursor-pointer flex justify-between items-start p-2 border border-b-0 rounded rounded-b-none bg-purple-100`}
                        >
                            <div className="text-gray-900 font-medium">
                                { i + 1} - {t(`projects.routine.action.type_${action.type}`)}
                            </div>
                            <Icon type={activeAction === i ? 'up' : 'down'} className="mr-2" />
                        </div>
                        {activeAction === i && (
                            <div className="flex flex-col space-y-2 justify-center items-start p-2 border rounded rounded-t-none bg-gray-50">
                                <ActionForm
                                    action={action}
                                    authorId={authorId}
                                    projectId={projectId}
                                    changeId={changeId}
                                    onSave={(action: RoutineAction) => updateAction(i, action)}
                                />
                                <div className="w-full flex flex-row justify-start">
                                    <Button
                                        small
                                        icon={isLoading ? 'loading' : 'delete'}
                                        color="danger"
                                        title={t('projects.routine.delete_action')}
                                        onClick={() => setToDelete(i)}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                ))}
                <div className="flex flex-row items-center space-x-0 border bg-blue-100 rounded">
                    <label className="max-w-[200px] flex-1 flex items-center text-sm px-2">
                        {t('projects.routine.add_action')}
                    </label>
                    <div className="flex-1">
                        <Select
                            defaultValue={typeToAdd}
                            options={RoutineAction.actionTypes().map((type: string) => ({
                                value: type,
                                label: t(`projects.routine.action.type_${type}`)
                            }))}
                            placeholder={t('projects.routine.choose_action_type_to_add') || ''}
                            onChange={(type: any) => setTypeToAdd(type)}
                            className="!mt-0 !border-0"
                            optionsWidth="w-[350px]"
                        />
                    </div>
                        {typeToAdd && (
                            <Button
                                className="h-[36px] rounded-l-none"
                                small
                                color="primary"
                                icon="plus"
                                title={t('common.add')}
                                onClick={() => addAction(typeToAdd)}
                            />
                        )}
                </div>
            </div>
            <div className="flex justify-between items-center bg-gray-50 p-2 border-y rounded rounded-t-none">
                <Button
                    small
                    icon={isLoading ? 'loading' : 'delete'}
                    color="danger"
                    title={t('projects.delete_routine')}
                    onClick={() => onRemove()}
                />
                <div className="flex flex-row space-x-2">
                    <Button
                        small
                        icon="back"
                        title={t('common.cancel')}
                        onClick={() => onCancel()}
                    />
                    <Button
                        small
                        color="primary"
                        icon={isLoading ? 'loading' : 'save'}
                        title={t('common.save')}
                        onClick={() => saveRoutine(routine)}
                    />
                </div>
            </div>
            <Confirm
                visible={toDelete >= 0}
                title={ t('projects.routine.are_you_sure_to_delete_this_action') || '' }
                onConfirm={() => { removeAction() }}
                onCancel={() => setToDelete(-1)}
            />
        </div>
    );
}

export default RoutineForm;
