import React, { useState, useEffect } from 'react';
import { useTranslation }  from 'react-i18next';
import { RootState, useAppSelector, useAppDispatch } from '../../../redux';
import { loadProject, loadUsers, loadBoards } from '../../../redux';
import { RoutineAction, User } from '../../../models';
import { TranslatableInput } from '../../TranslatableInput';
import { TypeField } from '../../TypeField';
import { UserAvatarField } from '../../UserAvatarField';
import { Icon } from '../../Icon';
import { Select } from '../../Select';
import _ from 'lodash';

interface CreateChangeFormProps {
    action: RoutineAction;
    onSave?: (action: RoutineAction) => void;
    authorId?: string;
    projectId?: string;
    changeId?: string;
}

function CreateChangeForm(props: CreateChangeFormProps) {
    const { action, onSave, authorId, projectId, changeId } = props;

    const { i18n, t } = useTranslation();
    const dispatch    = useAppDispatch();

    const [options, setOptions] = useState<AnyObj>({});

    const { users }   = useAppSelector((state: RootState) => state.users);
    const { boards }  = useAppSelector((state : RootState) => state.boards);
    const { project } = useAppSelector((state: RootState) => state.projects);

    useEffect(() => {
        if (projectId)
            dispatch(loadProject(projectId));
        dispatch(loadUsers());
    }, [dispatch]);

    useEffect(() => {
        if (projectId)
            dispatch(loadBoards({project: projectId}));
    }, [dispatch, projectId]);

    useEffect(() => {
        if (projectId && action?.options && !('project' in action.options))
            action.options.project = projectId;

        setOptions(action.options);
    }, []);

    useEffect(() => {
        onSave && onSave({
            ...action,
            options
        });
    }, [options]);

    const projectUsers = React.useMemo(() => {
        return users?.filter((user: User) => project?.hasMember(user));
    }, [users, project]);

    const boardOptions = React.useMemo(() => {
        return boards?.filter((board: AnyObj) => {
            return _.some(board.columns, (column: AnyObj) => !column.isSmart);
        })?.map((board: AnyObj) => ({
            value: board._id,
            label: board.name.fr
        }));
    }, [boards]);

    const columnOptions = React.useMemo(() => {
        const board = boards?.find((board: AnyObj) => board._id === options?.boardId);
        return board?.columns.filter((column: AnyObj) => !column.isSmart).map((column: AnyObj) => ({
            value: column._id,
            label: column.title
        }));
    }, [boards, options?.boardId]);

    /**
     * "type": "string",
     * "title": {},
     * "project": "string",
     * "estimate": 0,
     * "deadline": "string",
     * "deadlineOffset": 0,
     * "assignee": "string",
     * "boardId": "string",
     * "columnId": "string"
     */
    return (
        <div className="flex flex-col space-y-4 w-full">
            <div className="grid grid-cols-1 xl:grid-cols-4 gap-2 bg-purple-50 p-2 border rounded">
                <label className="text-sm col-span-1 flex items-center">
                    {t('projects.routine.action.change_type')}
                </label>
                <div className="col-span-3">
                    <TypeField
                        name="create_change_type"
                        defaultValue={options?.type}
                        className="bg-white p-2 rounded border"
                        onSave={(field: string, value: string) => { setOptions({...options, type: value })}}
                    />
                </div>
            </div>
            <div className="grid grid-cols-1 xl:grid-cols-4 gap-2 bg-purple-50 p-2 border rounded">
                <label className="text-sm col-span-1 flex items-center">
                    {t('projects.routine.action.change_title')}
                </label>
                <div className="col-span-3">
                    <TranslatableInput
                        name="create_change_title"
                        hideEditLink
                        inline
                        className="!text-sm !text-gray-900 bg-white !p-2 !border !border-gray-300 !border-r-0"
                        value={options?.title}
                        onChange={(value: AnyObj) => { setOptions({...options, title: value })}}
                    />
                </div>
            </div>
            <div className="grid grid-cols-1 xl:grid-cols-4 gap-2 bg-purple-50 p-2 border rounded">
                <label className="text-sm col-span-1 flex items-center">
                    {t('projects.routine.action.change_estimate')}
                </label>
                <div className="col-span-3">
                    <input
                        type="number"
                        step="0.5"
                        value={options?.estimate || 0}
                        onChange={(e: any) => setOptions({...options, estimate: e.target.value})}
                        placeholder={t('changes.set_estimate') || ''}
                        className="border rounded border-gray-300 w-full"
                    />
                </div>
            </div>
            <div className="grid grid-cols-1 xl:grid-cols-4 gap-2 bg-purple-50 p-2 border rounded">
                <label className="text-sm col-span-1 flex-col space-y-0 justify-center">
                    <div>{t('projects.routine.action.deadline_offset')}</div>
                    <small>{t('projects.routine.action.deadline_offset_desc')}</small>
                </label>
                <div className="col-span-3">
                    <input
                        type="number"
                        value={options?.deadlineOffset || 0}
                        onChange={(e: any) => setOptions({...options, deadlineOffset: e.target.value})}
                        placeholder={t('changes.deadline_offset') || ''}
                        className="border rounded border-gray-300 w-full"
                    />
                </div>
            </div>
            <div className="grid grid-cols-1 xl:grid-cols-4 gap-2 bg-purple-50 p-2 border rounded">
                <label className="text-sm col-span-1 flex items-center">
                    {t('projects.routine.action.change_assignee')}
                </label>
                <div className="col-span-3">
                    <UserAvatarField
                        name="assignee"
                        users={projectUsers}
                        className="bg-white p-1 rounded border"
                        defaultValue={options?.assignee}
                        onSave={(filed:string, assignee: string) => { setOptions({...options, assignee}); }}
                    />
                </div>
            </div>
            <div className="grid grid-cols-1 xl:grid-cols-4 gap-2 bg-purple-50 p-2 border rounded">
                <label className="text-sm col-span-1 flex-col space-y-0 justify-center">
                    <div>{t('projects.routine.action.choose_board')}</div>
                    <small>{t('projects.routine.action.choose_board_desc')}</small>
                </label>
                <div className="col-span-3">
                    {boardOptions && (
                        <Select
                            defaultValue={options?.boardId}
                            onChange={(boardId: any) => setOptions({...options, boardId})}
                            placeholder={t('changes.choose_a_board') || ''}
                            options={boardOptions}
                        />
                    )}
                </div>
            </div>
            {columnOptions && (
                <div className="grid grid-cols-1 xl:grid-cols-4 gap-2 bg-purple-50 p-2 border rounded">
                    <label className="text-sm col-span-1 flex-col justify-center">
                        <div>{t('projects.routine.action.choose_column')}</div>
                        <small>{t('projects.routine.action.choose_column_desc')}</small>
                    </label>
                    <div className="col-span-3">
                        <Select
                            defaultValue={options?.columnId}
                            onChange={(columnId: any) => setOptions({...options, columnId})}
                            placeholder={t('changes.choose_a_column') || ''}
                            options={columnOptions}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default CreateChangeForm;
