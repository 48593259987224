import { combineReducers } from 'redux';
import attachments from './attachments';
import auth from './auth';
import boards from './boards';
import changes from './changes';
import checklists from './checklists';
import clients from './clients';
import commits from './commits';
import comments from './comments';
import groups from './groups';
import projects from './projects';
import quotes from './quotes';
import sprints from './sprints';
import goals from './goals';
import tracks from './tracks';
import releases from './releases';
import routines from './routines';
import users from './users';
import logs from './logs';
import mercure from './mercure';
import documentations from './documentations';
import search from './search';
import statistics from './statistics';

export default combineReducers({
    attachments,
    auth,
    boards,
    changes,
    checklists,
    clients,
    commits,
    comments,
    groups,
    projects,
    quotes,
    sprints,
    goals,
    tracks,
    releases,
    routines,
    users,
    logs,
    mercure,
    documentations,
    search,
    statistics
});
